import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import React, { useEffect } from 'react';
import useTable from '../../hooks/useTable';
import { User, useUsersQuery } from '../../queries';
import NoResultsRow from '../NoResultsRow';
import TableLoading from '../TableLoading';
import FullNameAvatar from '../FullNameAvatar';
import useAuth from '../../hooks/useAuth';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory } from 'react-router';
import * as Routes from '../../constants/Routes';
interface CompanyUsersProps {
  companyId: number;
  selectedUsers: Partial<User>[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<Partial<User>[]>>;
  refresh: Date | undefined;
}

const getAddedBy = (user: Partial<User> | null | undefined) => {
  if (!user) {
    return 'Door Data Systems';
  }

  if (user.roles?.find((r) => r.name === 'Super User')) {
    return 'Door Data Systems';
  }

  return user.fullName;
};

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    marginRight: theme.spacing(1),
    fontSize: theme.typography.body1.fontSize,
  },
  activeStatus: {
    color: theme.palette.primary.main,
  },
  disabledStatus: {
    color: theme.palette.error.main,
  },
  chipCell: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  chip: {
    marginRight: 8,
    marginTop: 4,
    marginBottom: 4,
  },
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default function CompanyUsers({ companyId, setSelectedUsers, selectedUsers, refresh }: CompanyUsersProps) {
  const { page, pageSize, sortField, sortDirection, pageChanged, pageSizeChanged, sort } = useTable('fullName');
  const { loading, error, data, refetch } = useUsersQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      skip: pageSize * page,
      take: pageSize,
      sortDirection,
      sortField,
      companyId: companyId.toString(),
      includeDeleted: true,
    },
  });
  const { getRoleNameToDisplay, getRoleNamesForHover } = useAuth();

  const getUserStatus = (user: Partial<User> | null | undefined) => {
    const status = user?.deletedAt ? 'Disabled' : 'Active';
    const className = user?.deletedAt ? classes.disabledStatus : classes.activeStatus;
    return (
      <Typography variant="body2" className={className}>
        {status}
      </Typography>
    );
  };

  const isUserSelected = (user: Partial<User> | null | undefined): boolean => {
    return selectedUsers.filter((u) => u?.id === user?.id).length > 0;
  };

  const toggleUser = (user: Partial<User> | null | undefined, checked: boolean): void => {
    if (!user) {
      return;
    }

    if (checked) {
      setSelectedUsers((prevState) => [...prevState, user]);
    } else {
      setSelectedUsers((prevState) => prevState.filter((u) => u?.id !== user?.id));
    }
  };

  useEffect(() => {
    refetch();
  }, [refresh, refetch]);

  const classes = useStyles();
  const { loggedInUser } = useAuth();
  const history = useHistory();

  const onRowClick = (userId: string) => {
    history.push(`${Routes.Companies}/${companyId}/edit-user/${userId}`);
  };
  return (
    <Box mt={3}>
      <TableContainer component={Paper}>
        <Table aria-label="Company user table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox"></TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'fullName'}
                  direction={sortDirection}
                  onClick={() => sort('fullName')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel active={sortField === 'email'} direction={sortDirection} onClick={() => sort('email')}>
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Added by</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && <TableLoading />}
            {!loading && !error && data?.users.items.length === 0 && <NoResultsRow message="No users to display" />}
            {!loading && error && <NoResultsRow message="There was an error fetching users." />}
            {!loading &&
              !error &&
              data?.users &&
              data?.users.items.length > 0 &&
              data?.users.items.map((user, i) => (
                <TableRow hover key={i} onClick={() => onRowClick(user.id)} className={classes.tableRow}>
                  <TableCell>
                    {user.id !== loggedInUser?.id && (
                      <Checkbox
                        color="primary"
                        checked={isUserSelected(user as Partial<User>)}
                        onClick={(event) => event.stopPropagation()}
                        onChange={(e, checked) => toggleUser(user as Partial<User>, checked)}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <FullNameAvatar className={classes.avatar} fullName={user.fullName} />
                      {user.fullName}
                    </Box>
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell className={classes.chipCell}>
                    <Box display="flex" alignItems="center">
                      <Chip
                        label={
                          getRoleNameToDisplay(user.roles) + (user.roles.length > 1 ? ` +${user.roles.length - 1}` : '')
                        }
                        className={classes.chip}
                      />
                      {user.roles.length > 1 && (
                        <Tooltip title={getRoleNamesForHover(user.roles)} aria-label="role list">
                          <HelpIcon fontSize="small" />
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>{getAddedBy(user.addedBy as Partial<User>)}</TableCell>
                  <TableCell>{getUserStatus(user as Partial<User>)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={data?.users.total ?? 0}
          rowsPerPage={pageSize}
          page={page}
          onChangePage={(_, newPage) => pageChanged(newPage)}
          onChangeRowsPerPage={pageSizeChanged}
        />
      </TableContainer>
    </Box>
  );
}
